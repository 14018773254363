import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-invoice-payments-contact-us',
  templateUrl: './invoice-payments-contact-us.component.html',
})
export class InvoicePaymentsContactUsComponent implements OnInit {
  @Output() getUploadedBillOfLadingName = new EventEmitter();
  @Output() getUploadedPackingListName = new EventEmitter();
  @Input() parentForm!: UntypedFormGroup;
  @Input() invoicePaymentsForm!: UntypedFormGroup;

  billOfLadingName: string = '';
  packingListName: string = '';

  constructor() {}

  ngOnInit(): void {}

  getUploadedBillOfLading(evt: any) {
    this.billOfLadingName = evt;
    this.getUploadedBillOfLadingName.emit(this.billOfLadingName);
  }
  getUploadedPackingList(evt: any) {
    this.packingListName = evt;
    this.getUploadedPackingListName.emit(this.packingListName);
  }
}
