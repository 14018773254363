import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
import { Popover } from 'bootstrap';
import { IdentityService } from 'src/app/services/common/identity.service';
@Component({
  selector: 'app-complete-password',
  templateUrl: './complete-password.component.html',
})
export class CompletePasswordComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  cognitoUser: any;
  completePasswordForm: UntypedFormGroup;
  isError: boolean = false;
  isSuccess: boolean = false;
  loading: boolean = false;
  passwordPoliciesText: string =
    'Passwords must be at least 8 characters in length and contain characters from at least three of the following categories: Uppercase letters (A-Z), Lowercase letters (a-z), Numbers (base 10 digits 0 €" 9) or Non-alphabetic characters such as (` ~ ! @ # $ % ^ & * - + = | { } [ ] : ; < > , . ? /).';

  constructor(
    private authenticationService: AuthenticationService,
    private identityService: IdentityService,
    private route: Router,
    private formBuilder: UntypedFormBuilder
  ) {
    this.completePasswordForm = formBuilder.group(
      {
        newPassword: new UntypedFormControl('', [
          Validators.minLength(8),
          Validators.required,
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/),
        ]),
        confirmPassword: new UntypedFormControl('', [
          Validators.minLength(8),
          Validators.required,
        ]),
      },
      { validators: this.passwordsMatchValidator }
    );
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    var list = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    list.map(function (el: Element) {
      return Popover.getOrCreateInstance(el);
    });

    this.subscription.add(
      this.identityService.currentUser$.subscribe({
        next: (user) => {
          if (!user) {
            this.route.navigate(['login']);
          }
          this.cognitoUser = user;
        },
        error: (error) => {
          this.isError = true;
          console.error(error);
        },
      })
    );
  }
  completePassword() {
    this.loading = true;
    const password = this.completePasswordForm.get('newPassword')?.value;
    this.authenticationService
      .completeNewPassword(this.cognitoUser, password)
      .then((user) => {
        if (user) {
          this.loading = false;
          setTimeout(() => {
            this.route.navigate(['dashboard']);
          }, 3000);
        } else {
          this.isError = true;
          this.loading = false;
        }
      });
  }
  passwordsMatchValidator(resetPasswordForm: UntypedFormGroup) {
    return resetPasswordForm.controls['newPassword'].value ===
      resetPasswordForm.controls['confirmPassword'].value
      ? null
      : { passwordsMismatch: true };
  }
}
