import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '../../services/users/authentication.service';
import { HelperService } from '../../services/helper/helper.service';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  isValidBrowser: boolean = false;
  signInError = false;
  singInErrorMessage: string = '';
  loginForm: UntypedFormGroup;
  signInButtonActive: boolean = true;
  loading = false;
  // logInUrl
  logInUrl: any = '';
  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.loginForm = formBuilder.group({
      email: new UntypedFormControl('', [Validators.email, Validators.required]),
      password: new UntypedFormControl('', [
        Validators.minLength(8),
        Validators.required,
      ]),
      stayLogged: new UntypedFormControl(false),
    });
  }

  ngOnInit(): void {
    this.isValidBrowser = this.helperService.isBrowserValid();
    this.authenticationService.getCurrentToken()?.then((token) => {
      if (token && token.length > 1) {
        this.route.navigateByUrl('dashboard');
      }
    });
    this.logInUrl =
      this.activatedRoute.snapshot.queryParamMap.get('return') || '/dashboard';
  }

  continueAnyways() {
    this.isValidBrowser = true;
  }

  async login() {
    this.loading = true;
    this.signInError = false;
    this.singInErrorMessage = '';
    if (!this.loginForm.value.email || !this.loginForm.value.password) {
      return null;
    }
    try {
      const user = await this.authenticationService.signIn({
        email: this.loginForm.value.email.toString(),
        password: this.loginForm.value.password.toString(),
      });
      this.loading = false;
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.route.navigate(['activate']);
      } else {
        this.route.navigateByUrl(this.logInUrl);
      }
    } catch (error: any) {
      this.googleAnalyticsService.trackEvent(
        'login',
        'Unsuccessful',
        `${this.loginForm.value.email.toString()}`
      );
      if (error.message.includes('Incorrect username or password.')) {
        this.singInErrorMessage = 'Incorrect username or password.';
        this.signInError = true;
      } else if (error.message.includes('Password attempts exceeded')) {
        this.singInErrorMessage =
          'Maximum login attempts exceeded. Please try again later.';
        this.signInError = true;
        // this.signInButtonActive = false;
      }
      this.loading = false;
    }
  }
}
