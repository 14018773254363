import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Color } from 'ngx-color';

@Component({
  selector: 'app-reactive-color-selector',
  templateUrl: './reactive-color-selector.component.html',
})
export class ReactiveColorSelectorComponent {
  @Input() colorFormControl!: UntypedFormControl;
  @Input() color: string = '#fff';

  constructor() {}

  changeColor(event: any) {
    // console.log(event.color.hex);
    if (event.color && this.colorFormControl) {
      this.colorFormControl.setValue(event.color.hex);
      this.colorFormControl.markAsDirty();
    }
  }
}
