import { IdentityService } from 'src/app/services/common/identity.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  catchError,
  firstValueFrom,
  map,
  Observable,
  throwError,
  timeout,
} from 'rxjs';
import {
  IUser,
  IUserFormats,
  EmailOptInData,
  EmailOptIn,
  IUserListParams,
} from '../../models/user';
import { HttpUrlEncoding } from 'src/app/utils/http-url-encoding';
import { HttpHelperService } from '../helper/http-helper.service';
import { RolesHelperService } from '../helper/roles-helper.service';
import { RoleType } from 'src/app/models/role';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpHelperService: HttpHelperService,
    private identityService: IdentityService,
    private rolesHelperService: RolesHelperService
  ) {}

  getUserList(params: IUserListParams): Observable<IUser[]> {
    let queryParams = this.parseUserListParams(params);
    return this.httpHelperService.get('/users/list', queryParams).pipe(
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  public getUser(userId: string, supplierNumber?: string): Observable<IUser> {
    if (!userId) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    const ssNumber = this.supplierNumberUrlParam(supplierNumber);
    return this.httpHelperService.get(`/users/${userId}${ssNumber}`).pipe(
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  getMeUser() {
    return this.httpHelperService.get(`/users/me`).pipe(
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  public async getCurrentUser() {
    const userValue = this.identityService.currentUser$.getValue() as any;

    if (userValue?.username) {
      return firstValueFrom(
        this.httpHelperService.get(`/users/${userValue.username}`)
      );
    }
  }

  createUser(user: IUser, supplierNumber?: string) {
    if (!user) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    user.enable = true;
    delete user.status;
    user = this.parseForEmailOptIn(user);
    const ssNumber = this.supplierNumberUrlParam(supplierNumber);
    return this.httpHelperService
      .post(`/users${ssNumber}`, this.parseUser(user))
      .pipe(
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('Users API. Details: ' + err.error.message);
        })
      );
  }

  updateUser(userId: string | undefined, user: IUser, supplierNumber?: string) {
    if (!userId || !user) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    this.prepareUser(user);
    user = this.parseForEmailOptIn(user);
    user = this.removeSupplierNumberForFender(user);
    console.log('update user', user);
    const ssNumber = this.supplierNumberUrlParam(supplierNumber);
    return this.httpHelperService.put(`/users/${userId}${ssNumber}`, user).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  deleteUser(userId: string | undefined, supplierNumber?: string) {
    if (!userId) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    const ssNumber = this.supplierNumberUrlParam(supplierNumber);
    return this.httpHelperService.delete(`/users/${userId}${ssNumber}`).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  resetUser(userId: string | undefined) {
    if (!userId) {
      return throwError(() => new Error('Token or User Id Must Be Provided'));
    }
    return this.httpHelperService.put(`/users/reset/${userId}`, {}).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  downloadUsersFile() {
    return this.httpHelperService.get(`/jobs/downloads/user-report`).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  updateUserPreferences(preferences: IUserFormats) {
    if (!preferences) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpHelperService.put(`/users/me`, preferences).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  validateUserSafePassword(password: string) {
    const passObj = {
      newPassword: password,
    };
    return this.httpHelperService.post(`/users/whitelist`, passObj).pipe(
      timeout(environment.api.timeout),
      catchError((err) => {
        throw new Error('Users API. Details: ' + err.error.message);
      })
    );
  }

  //* Private Functions */
  private prepareUser(user: IUser): void {
    if (user.status) {
      user.enable = user.status === 'active' ? true : false;
      delete user.status;
    }
  }

  private parseForEmailOptIn(user: IUser): IUser {
    const emailOptInData: EmailOptInData = {
      onDocumentCreated: {},
      onPoCreated: {
        supplierNumbers: [],
      },
    };
    if (this.rolesHelperService.getRoleType(user.role) === RoleType.SUPPLIER) {
      if (user.emailOptIn?.includes(EmailOptIn.PURCHASE_ORDERS)) {
        emailOptInData.onPoCreated.supplierNumbers.push(user.supplierNumber!);
      } else {
        emailOptInData.onPoCreated.supplierNumbers.splice(0, 1);
      }
      user.emailOptInData = emailOptInData;
    }
    return user;
  }

  private parseUserListParams(params: IUserListParams) {
    let queryParams: HttpParams = new HttpParams({
      encoder: new HttpUrlEncoding(),
    });
    if (params.limit) {
      queryParams = queryParams.append('limit', params.limit);
    }
    if (typeof params.offset === 'number') {
      queryParams = queryParams.append('offset', params.offset);
    }
    if (params.sortType) {
      queryParams = queryParams.append('sortType', params.sortType);
    }
    if (params.sortBy) {
      queryParams = queryParams.append('sortBy', params.sortBy);
    }
    if (params.search) {
      queryParams = queryParams.append('search', params.search);
    }
    if (params.supplierNumber) {
      queryParams = queryParams.append('supplierNumber', params.supplierNumber);
    }
    return queryParams;
  }

  private removeSupplierNumberForFender(user: IUser) {
    if (user.role.includes('FMIC')) {
      user.supplierNumber = '';
    }
    return user;
  }

  supplierNumberParam(supplierNumber: string | undefined): HttpParams {
    let queryParams: HttpParams = new HttpParams({
      encoder: new HttpUrlEncoding(),
    });
    if (supplierNumber) {
      queryParams = queryParams.append('supplierNumber', supplierNumber);
    }
    return queryParams;
  }

  supplierNumberUrlParam(supplierNumber: string | undefined): string {
    if (!supplierNumber) {
      return '';
    }
    return `?supplierNumber=${supplierNumber}`;
  }

  private parseUser(user: IUser) {
    user.email = user.email?.toLowerCase();
    return user;
  }
}
