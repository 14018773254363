import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const loggerService = this.injector.get(LoggerService);
    loggerService.logError(error.message || error.toString())
  }
}
