import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IdentityService } from 'src/app/services/common/identity.service';

@Component({
  selector: 'app-events-background-selector',
  templateUrl: './events-background-selector.component.html',
})
export class EventsBackgroundSelectorComponent implements OnInit {
  constructor(private identityService: IdentityService) {}

  @Input() parentForm!: UntypedFormGroup;
  @Input() createSectionForm!: UntypedFormGroup;
  @Input() userInfo!: any;
  // User

  // Select
  backgoundSelected: string = '';
  // Color Sketch
  color: string = '#fff';
  filename: string = '';
  selectedFileName: string = '';
  isEditMode: boolean = false;

  async ngOnInit() {
    if (this.parentForm.get('backgroundType')?.value) {
      this.setValuesOnEdit();
    }
    this.identityService.getCurrentUserInfo().subscribe((data) => {
      this.userInfo = data;
    });
  }

  setValuesOnEdit() {
    this.backgoundSelected = this.parentForm.get('backgroundType')?.value;
    if (this.backgoundSelected === 'RGB') {
      this.color = this.parentForm.get('background')?.value;
    }
  }
  selectTextBackgroundStyle(event: any) {
    this.backgoundSelected = event;
  }

  getUploadedFile($event: any) {
    this.filename = $event;
    this.setImagePathValue();
  }

  setImagePathValue() {
    const pathName = this.prepareComposedFileName();
    this.parentForm.get('backgroundPath')?.patchValue(pathName);
  }

  prepareComposedFileName() {
    if (this.filename) {
      return `${this.userInfo.id}/${this.filename}`;
    } else {
      return `${this.selectedFileName}`;
    }
  }

  onBackgroundTypeChange(event: any) {
    this.backgoundSelected = event;
    this.parentForm.get('background')?.setValue('');
  }
}
