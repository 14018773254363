import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Player, PlayerEvent, SubtitleEvent } from 'bitmovin-player';
// import { UIFactory } from 'bitmovin-player-ui';
import { EventsService } from '../../../services/events/events.service';
import { GoogleAnalyticsService } from '../../../services/common/google-analytics.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bitmovin-video-player',
  templateUrl: 'bitmovin-video-player.component.html',
})
export class BitMovinVideoPlayerComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Output() onCloseVideo = new EventEmitter();
  @Output() activityChange = new EventEmitter();
  @Output() onSubtitleSelected = new EventEmitter();

  @Input() videoId!: string;
  @Input() videoTitle!: string;
  @Input() videoUrl!: string;
  @Input() eventId!: string | null;
  @Input() trackActivity!: boolean;
  @Input() videoActivity: any;
  @Input() videoSubtitles!: any[];
  @Input() eventCategory!: string;
  @Input() pageSelected!: string | null;
  @Input() pageTitle!: string;
  @Input() startTime: number = 0;

  public player: any;
  loading!: boolean;
  autoplay!: boolean;
  currentTimeOnDestroy!: number;
  currentDurationOnDestroy!: number;
  timeWatched!: number;
  private sub: Subscription = new Subscription();
  EVideoProgress = {
    NOT_WATCHED: 'NotWatched',
    NOT_FINISHED: 'NotFinished',
    WATCHED: 'Watched',
  };
  statusChanged: any;
  constructor(
    private ga: GoogleAnalyticsService,
    private videoActivityService: EventsService
  ) {}
  ngOnDestroy(): void {
    const currentTime = this.player.getCurrentTime();
    const durationTime = this.player.getDuration();
    let videoProgress = this.EVideoProgress.NOT_FINISHED;
    let progressPercentage = Math.ceil((currentTime / durationTime) * 100);
    if (progressPercentage >= 95) {
      videoProgress = this.EVideoProgress.WATCHED;
    }
    const sectionId = this.videoId;
    const eventId = this.eventId;
    const pageSelected = this.pageSelected;

    const videoActivity: any = {
      eventId,
      pageId: pageSelected,
      sectionId,
      progress: Math.ceil(currentTime),
      progressPercentage: progressPercentage,
      total: Math.ceil(durationTime),
      status: videoProgress,
    };
    this.onActivityChange(videoActivity);
  }
  closeVideo(): void {
    this.onCloseVideo.emit('onCloseVideo');
  }

  onActivityChange(activity: any) {
    if (activity) this.activityChange.emit(activity);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['videoActivity'] && changes['videoActivity'].currentValue) {
      this.timeWatched = this.calculateTimeWatched(
        this.videoActivity.timeWatched
      );
    }
  }

  ngOnInit() {
    this.loading = true;
    this.autoplay = true;
    this.setPlayer();
    this.player.on(PlayerEvent.Ready, () => {
      // If there is no activity for the user in this video create one
    });
    this.player.on(PlayerEvent.Paused, () => {
      this.addVideoActivity(
        this.player.getDuration(),
        this.player.getCurrentTime(),
        this.EVideoProgress.NOT_FINISHED
      );
    });

    this.player.on(PlayerEvent.PlaybackFinished, () => {
      this.addVideoActivity(
        this.player.getDuration(),
        this.player.getCurrentTime(),
        this.EVideoProgress.WATCHED
      );
    });

    const source: any = {};
    if (this.isHLS(this.videoUrl)) {
      source['hls'] = this.videoUrl;
    } else {
      source['progressive'] = this.videoUrl;
    }
    source['options'] = { startTime: this.startTime };
    this.player.on(PlayerEvent.SubtitleEnable, (eventInfo: SubtitleEvent) => {
      this.onSubtitleSelected.emit(eventInfo.subtitle.label);
    });

    this.player.load(source).then(() => {
      setTimeout(() => {
        this.setVideoSubtitles();

        if (document.querySelector('button.bmpui-ui-watermark')) {
          document
            .querySelector('button.bmpui-ui-watermark')!
            .setAttribute('disabled', 'disabled');
        }
      }, 1000);
    });
  }

  recordStatus() {
    if (
      this.player.getCurrentTime() > this.timeWatched &&
      this.videoActivity &&
      this.videoActivity.progress.toUpperCase() !== 'WATCHED'
    ) {
      this.addVideoActivity(
        this.player.getDuration(),
        this.player.getCurrentTime(),
        this.EVideoProgress.NOT_FINISHED
      );
    }
  }

  stopClickPropagation(event: { stopPropagation: () => void }) {
    if (event) {
      event.stopPropagation();
    }
  }

  private calculateTimeWatched(timeWatchedString: string): number {
    if (!timeWatchedString) {
      return 0;
    }
    let timeWatched = 0;
    const timeWatchedArray = timeWatchedString.split(':');
    const minutesWatched = parseInt(timeWatchedArray[0]) * 60; // Convert into seconds
    const secondsWatched = parseInt(timeWatchedArray[1]);
    timeWatched = minutesWatched + secondsWatched;
    return timeWatched;
  }

  /**
   * Send Video Activity for activity reports
   *
   *
   * @param number durationTime  video duration.
   * @param number currentTime  video current time when paused or destroyed.
   * @param string videoProgress  video status constant.
   */

  private addVideoActivity(
    durationTime: number,
    currentTime: number,
    videoProgress: string
  ): void {
    if (!durationTime || !currentTime || !videoProgress) {
      return;
    }
    let progressPercentage = Math.ceil((currentTime / durationTime) * 100);
    if (progressPercentage >= 95) {
      videoProgress = this.EVideoProgress.WATCHED;
    }
    const sectionId = this.videoId;
    const eventId = this.eventId;
    const pageSelected = this.pageSelected;

    //  todo: Interface
    const videoActivity: any = {
      eventId,
      pageId: pageSelected,
      sectionId,
      progress: Math.ceil(currentTime),
      progressPercentage: progressPercentage,
      total: Math.ceil(durationTime),
      status: videoProgress,
    };
    this.onActivityChange(videoActivity);
    this.videoActivityService
      .trackActivityReport(eventId, sectionId, videoActivity)
      .subscribe(() => {});
  }
  /**
   * Set Video Player configurations
   */

  private setPlayer() {
    this.player = new Player(document.getElementById('bitmovinplayer')!, {
      key: '7cd964f6-cbc6-436f-bec2-7d8c2e825ceb', //  TODO replace this string in webpack deploy
      location: {
        ui: 'assets/js/bitmovinplayer-ui.js',
        ui_css: 'assets/css/bitmovinplayer-ui.css',
      },
      style: {
        width: '100%',
        aspectratio: '16:9',
      },
      playback: {
        autoplay: true,
      },
    });
  }
  /**
   * Identify if the video url provided is a  HLS or Progresive
   * @param string videoUrl video url.
   */
  private isHLS(videoUrl: string) {
    if (!videoUrl) {
      return;
    }
    const videoName = videoUrl.split('/').pop();
    return videoName ? videoName.includes('.m3u8') : false;
  }

  private setVideoSubtitles() {
    if (this.videoSubtitles && this.videoSubtitles.length > 0) {
      // todo: IVideoSubtitles interface
      this.videoSubtitles.forEach((subtitle: any) => {
        // todo: IVideoSubtitlesBitmovin interface
        const subtitleObj: any = {
          id: subtitle?.id ? subtitle.id : `sub_${subtitle.langCode}`,
          lang: subtitle.langCode,
          label: subtitle.label,
          url: subtitle.url,
          kind: subtitle?.kind ? subtitle.kind : 'subtitle',
        };
        this.player.subtitles.add(subtitleObj);
      });
    }
  }
}
