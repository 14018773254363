// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { CookieStorage } from 'amazon-cognito-identity-js';

export const environment = {
  production: false,
  environment: 'development',
  domain: 'development.supplier.fender.com',
  cognito: {
    identityPoolId: 'us-west-2:96f0cb04-d024-40e1-bb26-061264033bd8',
    userPoolWebClientId: '7u35hk4l419iprfs091pqauaca',
    userPoolId: 'us-west-2_LWMxRKjUM',
    region: 'us-west-2',
    authenticationFlowType: 'USER_SRP_AUTH',
    cookieStorage: new CookieStorage({
      domain: 'development.supplier.fender.com',
      secure: false,
      path: '/',
      expires: 1,
      sameSite: 'strict',
    }),
  },
  api: {
    endPoint: 'https://development.api.supplier.fender.com',
    timeout: 180000,
    users: {
      limit: 60,
    },
  },
  storage: {
    AWSS3: {
      bucket: 'development.storage.supplier.portal.com',
      region: 'us-west-2',
    },
  },
  websocket: {
    url: 'wss://development.alerts.supplier.fender.com',
  },
  google: { analytics: 'G-NWTT4KPWZX' },
  cloudwatch: {
    logGroupName: 'frontend-development.supplier.fender',
    logStreamName: 'frontend-development.supplier.fender-stream',
    region: 'us-west-2',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
