import { Injectable } from '@angular/core';
import Amplify, { AWSCloudWatchProvider, Logger, Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private logger: Logger;

  constructor() {
    const dynamicLogStreamName = `${environment.cloudwatch.logStreamName}-${new Date().toISOString()}`;

    console.log('Adding AWSCloudWatchProvider');
    Amplify.addPluggable(
      new AWSCloudWatchProvider({
        logGroupName: environment.cloudwatch.logGroupName,
        logStreamName: dynamicLogStreamName,
        region: environment.cloudwatch.region,
      })
    );

    this.logger = new Logger('AppLogger');
    console.log(`LoggerService initialized with log stream: ${dynamicLogStreamName}`);

  }

  logError(message: string) {
    try {
      console.log('Logging error to CloudWatch:', message);
      this.logger.error(message);
      console.log('Logged error to CloudWatch:', message);
    } catch (error) {
      console.error('Error logging to CloudWatch:', error);
    }
  }
}
