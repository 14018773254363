import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ROLES } from 'src/app/models/role';
import { IUser } from 'src/app/models/user';
import { SelectSupplierHelperService } from 'src/app/services/helper/select-supplier-helper.service';
import { EventsService } from '../../services/events/events.service';
import { IdentityService } from '../../services/common/identity.service';
import {
  mainMenuOptions,
  supplierFactoryMainMenuOptions,
} from 'src/app/fixtures/main-menu/main-menu-options';
import {
  IMainMenu,
  MainMenuOptionName,
  PoOptionSubMenuOptions,
} from 'src/app/models/main-menu';
import { IMainSubMenu } from '../../models/main-menu';
import { EventType, IOSEEvents } from 'src/app/models/events';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
})
export class MainMenuComponent implements OnInit {
  subscription: Subscription = new Subscription();
  menuIsOpen: boolean = false;
  supplierNumber: string | undefined = undefined;
  menuArray: any[] = [];
  customPagesArray: any[] = [];

  // User info
  userId!: string;
  mUser!: IUser;
  canSelectSupplier: boolean = false;

  // Events
  activeEvents: any = [];

  constructor(
    private selectSupplierHelperService: SelectSupplierHelperService,
    private identityService: IdentityService,
    private eventsService: EventsService,
    private route: Router
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.identityService.getCurrentMeUser().subscribe((user) => {
      if (user) {
        this.createMainMenuArray(user.role);
        this.setUserProperties(user);
      }
    });
    this.listenCustomPagesChanges();
  }

  createMainMenuArray(role: string) {
    this.menuArray = this.getMenuOptions(role);
    this.getEvents();
    this.getCustomPages();
  }

  getMenuOptions(role: string) {
    if (role === ROLES.SUPPIER_FACTORY) {
      return [...supplierFactoryMainMenuOptions];
    }
    return [...mainMenuOptions];
  }

  listenCustomPagesChanges() {
    this.eventsService.getDynamicMainMenuSubject().subscribe((param: any) => {
      if (param !== undefined) {
        this.getCustomPages();
      }
    });
  }

  setUserProperties(user: IUser) {
    this.mUser = user;
    this.checkRoleForSelectSupplier(this.mUser.role);
    this.checkRoleForPoPage(this.mUser.role);
  }

  navigatePage(page: any) {
    if (!page.subItems || !page.subItems.length) {
      switch (page.route) {
        case 'custom-pages':
          this.navigateToCustomPage(page);
          break;
        default:
          this.route.navigate([page.route]);
          break;
      }
    } else {
      switch (page.route) {
        case 'events':
          this.navigateToEvents(page);
          break;
      }
    }
    this.closeMenu();
  }

  navigateToEvents(page: any) {
    if (page.subItems.length == 1) {
      this.route.navigate([`/events/${page.subItems[0].id}`], {
        queryParams: { pageIds: page.subItems[0]?.pageIds.join() },
      });
    }
  }

  navigateToCustomPage(page: any) {
    this.route.navigate([`/custom-pages/${page.customPageId}`], {
      queryParams: { pageIds: page.pagesId?.toString() },
    });
  }

  checkRoleForPoPage(role: string) {
    if (
      role === ROLES.FMIC_ADMIN ||
      role === ROLES.FMIC_CUSTOMER_SERVICE ||
      role === ROLES.FMIC_MANAGER
    ) {
      this.subscription.add(
        this.selectSupplierHelperService.getValue().subscribe((value) => {
          this.setVisibilityValues(false, value ? true : false, role);
        })
      );
    }
    if (
      role === ROLES.SUPPIER_ADMIN ||
      role === ROLES.SUPPIER_MANAGER ||
      role === ROLES.SUPPIER_FINANCE
    ) {
      // this.supplierNumber = this.mUser.supplierNumber;
      this.setVisibilityValues(true);
    }
  }

  setVisibilityValues(
    isSupplier: boolean,
    hasSupplierNumber?: boolean,
    role?: string
  ) {
    // FMIC User & NO supplierSelected
    if (!isSupplier && !hasSupplierNumber) {
      this.changePoOptionSubItemsVisibility([PoOptionSubMenuOptions.SUMMARY]);
    }
    // FMIC User & supplierSelected
    if (!isSupplier && hasSupplierNumber) {
      if (role == ROLES.FMIC_MANAGER) {
        this.changePoOptionSubItemsVisibility([PoOptionSubMenuOptions.SUMMARY]);
        return;
      }
      this.changePoOptionSubItemsVisibility([
        PoOptionSubMenuOptions.BY_PO,
        PoOptionSubMenuOptions.BY_MODEL,
        PoOptionSubMenuOptions.SUMMARY,
      ]);
    }
    // Supplier User ===========
    if (isSupplier && role !== ROLES.FMIC_MANAGER) {
      this.changePoOptionSubItemsVisibility([
        PoOptionSubMenuOptions.BY_PO,
        PoOptionSubMenuOptions.BY_MODEL,
      ]);
    }
  }

  changePoOptionSubItemsVisibility(visbleOptions: string[]) {
    this.menuArray
      .find(
        (item: IMainMenu) => item.name == MainMenuOptionName.PURCHASE_ORDERS
      )
      .subItems.forEach((subItem: IMainSubMenu) => {
        subItem.visible = visbleOptions.includes(subItem.title);
      });
  }

  checkRoleForSelectSupplier(role: string | undefined) {
    if (
      role === ROLES.FMIC_ADMIN ||
      role === ROLES.FMIC_CUSTOMER_SERVICE ||
      ROLES.FMIC_MANAGER
    ) {
      this.canSelectSupplier = true;
    } else {
      this.canSelectSupplier = false;
    }
  }

  getEvents() {
    this.eventsService.getActiveEvents(EventType.OSE).subscribe({
      next: (events: any[]) => {
        let eventMenuObj = this.menuArray.find(
          (item: any) => item.name === MainMenuOptionName.EVENTS
        );
        if (events.length) {
          events.forEach((event: any) => {
            event.visible = true;
            eventMenuObj?.subItems?.push(event);
          });
          eventMenuObj!.visible = true;
        } else {
          eventMenuObj!.visible = false;
        }
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {},
    });
  }

  getCustomPages() {
    this.eventsService.getActiveEvents(EventType.CUSTOM_PAGES).subscribe({
      next: (pages: IOSEEvents[]) => {
        this.setCustomPages(pages);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  setCustomPages(pages: IOSEEvents[]) {
    let newCPArray: any[] = [];
    pages.forEach((page: IOSEEvents) => {
      newCPArray.push({
        name: page.title,
        navigationIcon: page.navigationIcon,
        route: `custom-pages`,
        visible: page.pageIds?.length,
        isCustom: true,
        customPageId: page.id,
        pagesId: page.pageIds,
      });
    });
    this.customPagesArray = [...newCPArray];
  }

  selectSubItem(event: any) {
    const { menuItem, subItem } = event;
    switch (menuItem.route) {
      case 'events':
        this.route.navigate([`/events/${subItem.id}`], {
          queryParams: { pageIds: subItem?.pageIds.join() },
        });
        break;
      case 'purchase-orders':
        this.route.navigate([`/purchase-orders/${subItem.route}`]);
        break;
      default:
    }
  }

  mainMenuClick() {
    this.menuIsOpen = !this.menuIsOpen;
  }
  closeMenu() {
    this.menuIsOpen = false;
  }
}
