import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';
import { ROLES } from 'src/app/models/role';
import { IUser } from 'src/app/models/user';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';
import { SelectSupplierHelperService } from 'src/app/services/helper/select-supplier-helper.service';
import { PurchaseOrdersService } from 'src/app/services/purchase-orders/purchase-orders.service';
import { UserService } from 'src/app/services/users/user.service';
import {
  CurrencyCode,
  IPoFilterParams,
  IPoPurchaserResponse,
  IPurchaseOrder,
  IPurchaseOrdersListResponse,
} from '../../../models/purchase-orders';
import { WebsocketService } from '../../../services/lib/websocket.service';
import { ToastAlertComponent } from 'src/app/ui/toast-alert/toast-alert.component';
import { plantLocations } from 'src/app/fixtures/plants';
import { IdentityService } from '../../../services/common/identity.service';
import { UntypedFormControl } from '@angular/forms';
import { HelperService } from '../../../services/helper/helper.service';
import { TimeoutConfig } from 'src/app/fixtures/app-settings/constants';
import { sortBy } from 'cypress/types/lodash';
import { PoHelperService } from 'src/app/services/purchase-orders/po-helper.service';
import { NewUiSelectComponent } from 'src/app/ui/forms/new-ui-select/new-ui-select.component';
import { INewUISelect } from 'src/app/models/ui-components';
import { PoDropdownDateFilterComponent } from 'src/app/ui/purchase-orders/po-dropdown-date-filter/po-dropdown-date-filter.component';
import { ProductLineFilterComponent } from '../components/product-line-filter/product-line-filter.component';

@Component({
  selector: 'app-purchase-orders-page',
  templateUrl: './purchase-orders-page.component.html',
})
export class PurchaseOrdersPageComponent implements OnInit {
  constructor(
    private purchaseOrdersService: PurchaseOrdersService,
    private poHelperService: PoHelperService,
    private selectSupplierHelperService: SelectSupplierHelperService,
    private helperService: HelperService,
    private identityService: IdentityService,
    private websocketService: WebsocketService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  subscription: Subscription = new Subscription();
  purchaseOrdersSubscription: Subscription = new Subscription();

  // Roles & Permissions
  fenderRolesPermissions: string[] = [
    ROLES.FMIC_ADMIN,
    ROLES.FMIC_CUSTOMER_SERVICE,
  ];
  supplierRolesPermissions: string[] = [
    ROLES.SUPPIER_ADMIN,
    ROLES.SUPPIER_MANAGER,
    ROLES.SUPPIER_FINANCE,
  ];

  // User
  mUser!: IUser;
  token: string | null = '';
  userId!: string;
  currentSupplierNumber: string = '';
  dateFormat: string = 'dd-MM-yyyy';
  currencyArray: any = Object.values(CurrencyCode);
  purchaseOrdersFilterParams = {};

  // Form Control
  filterStringControl = new UntypedFormControl('');

  // Filters
  poFilterParams: IPoFilterParams = {
    limit: 50,
    offset: 0,
    createDate: '',
  };

  // Filters v2
  hasAlerts: boolean = true;

  // ================== test

  purchaserOptions: INewUISelect[] = [];
  statusOptions: INewUISelect[] = [...this.poHelperService.setStatusOptions()];

  // ChangingValues
  pageIsByModel: boolean = false;
  pageIsByPo: boolean = false;
  pageIsSummary: boolean = false;

  // Page Setting
  poPageSetting: string | undefined = undefined;

  // Pagination
  currentPage: number = 0;

  // Arrays
  dateRangeOptions: any[] = [];

  // PO
  purchaseOrderTotal: number = 0;
  openQuantityTotal: number = 0;
  purchaseOrdersList: IPurchaseOrder[] = [];

  // Loading & Alerts
  loadingTable: boolean = false;
  loadingReport: any = {
    loader: false,
  };
  loadingPoPdf: any = {
    loader: false,
  };
  loadingPoPdfIndex: number | undefined = undefined;
  toastErrorText: string = '';
  loadingPlants: boolean = false;

  // Toast Components
  @ViewChild('errorToast') errorToast!: ToastAlertComponent;

  // New UI Selects
  @ViewChild('statusSelect', { static: false })
  statusSelect?: NewUiSelectComponent;
  @ViewChild('purchaserSelect', { static: false })
  purchaserSelect?: NewUiSelectComponent;
  @ViewChild('dateRangeFilterDropdown', { static: false })
  dateRangeFilterDropdown?: PoDropdownDateFilterComponent;
  @ViewChild('productLineFilter', { static: false })
  productLineFilter?: ProductLineFilterComponent;

  // Plant
  preselectedPlant = [];
  plantNames: number[] = [];
  plantLabels: string[] = [];

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.getPageFromRouteParameter();
    this.dateRangeOptions = this.purchaseOrdersService.getDateRageList();

    // get currentMeUser
    this.identityService.getCurrentMeUser().subscribe((user) => {
      if (user) {
        this.mUser = user;
      }
    });

    // ===== Summary
    if (this.pageIsSummary) {
      this.getPurchasers();
      this.getPurchaseOrders();
      this.getValuesForPlant();
    }
    if (this.pageIsByPo) {
      this.filterGroupBy('poNumber');
    }
    // ===== By MODEL & PO
    if (this.pageIsByModel || this.pageIsByPo) {
      // Fernder Role
      if (this.fenderRolesPermissions.includes(this.mUser.role)) {
        this.getFMICSupplierNumber();
      }
      // Supplier Role
      if (
        this.supplierRolesPermissions.includes(this.mUser.role) &&
        this.mUser.supplierNumber
      ) {
        this.currentSupplierNumber = this.mUser.supplierNumber;
        this.poFilterParams.supplierNumber = this.mUser.supplierNumber;
        this.getPurchaseOrders();
        this.getValuesForPlant();
      }
    }

    this.listenFilterStringControl();
    this.setDateFormatIfNonExisting(this.mUser.dateFormat);
    this.onMessageHandlerErrorHandler();
  }

  getFMICSupplierNumber() {
    this.subscription.add(
      this.selectSupplierHelperService.getValue().subscribe((value) => {
        if (value) {
          this.currentSupplierNumber = value;
          this.poFilterParams.supplierNumber = value;
          this.getPurchaseOrders();
          this.getValuesForPlant();
        }
      })
    );
  }

  setDateFormatIfNonExisting(userDateFormat: string | undefined) {
    this.dateFormat = userDateFormat ? userDateFormat : 'MM-dd-yyyy';
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.poFilterParams.offset = (this.currentPage - 1) * 50;
    this.getPurchaseOrders();
  }

  changeSort(sort: any) {
    this.poFilterParams.sortType =
      this.poFilterParams.sortBy === sort &&
      this.poFilterParams.sortType === 'desc'
        ? 'asc'
        : 'desc';
    this.poFilterParams.sortBy = sort;
    this.getPurchaseOrders();
  }

  goToPurchaseOrderDetail(
    poNumber: string | undefined,
    supplierNumber?: string
  ) {
    if (!supplierNumber) {
      this.router.navigateByUrl(`/purchase-orders/${poNumber}`);
    } else {
      this.router.navigate([
        `/purchase-orders/${poNumber}`,
        { supplierNumber },
      ]);
    }
  }

  donwloadPdf(poNumber: string, index: number) {
    this.loadingPoPdf.loader = true;
    this.loadingPoPdf.timer = setTimeout(() => {
      this.loadingPoPdf.loader = false;
      this.loadingPoPdfIndex = undefined;
    }, TimeoutConfig.WEBSOCKET_LOADER);
    this.loadingPoPdfIndex = index;
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Click',
      'PDF Download'
    );
    if (!poNumber) {
      return null;
    }
    this.subscription.add(
      this.purchaseOrdersService.downloadPoPdf(poNumber).subscribe({
        next: (data) => {
          if (data && data.url) {
            this.helperService.downloadFromUrl(data.url);
            this.loadingPoPdf.loader = false;
          }
          if (data.message === 'No document found') {
            this.toastErrorText = data.message;
            this.errorToast?.show();
            this.loadingPoPdf.loader = false;
            clearTimeout(this.loadingPoPdf.timer);
          }
        },
        error: (err) => {
          console.error(err);
        },
      })
    );
  }

  donwloadPoReports() {
    this.loadingReport.loader = true;
    this.loadingReport.timer = setTimeout(() => {
      this.loadingReport.loader = false;
    }, TimeoutConfig.WEBSOCKET_LOADER);
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Click',
      'Export Open Orders'
    );
    if (this.pageIsSummary) {
      this.subscription.add(
        this.purchaseOrdersService
          .dowloadPoSummaryReport(this.poFilterParams)
          .subscribe({
            next: (data) => {
              if (data && data.url) {
                this.helperService.downloadFromUrl(data.url);
                this.loadingReport.loader = false;
                clearTimeout(this.loadingReport.timer);
              }
            },
            error: (err) => {
              console.error(err);
            },
          })
      );
    }

    if (this.pageIsByModel || this.pageIsByPo) {
      this.subscription.add(
        this.purchaseOrdersService
          .dowloadPoReport(this.poFilterParams)
          .subscribe({
            next: (data) => {
              if (data && data.url) {
                this.helperService.downloadFromUrl(data.url);
                this.loadingReport.loader = false;
                clearTimeout(this.loadingReport.timer);
              }
            },
            error: (err) => {
              console.error(err);
            },
          })
      );
    }
  }

  listenFilterStringControl() {
    this.filterStringControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((text) => {
          this.purchaseOrdersList = [];
          this.loadingTable = true;
          this.poFilterParams.search = text;
          if (this.pageIsSummary) {
            return this.purchaseOrdersService.getSummaryPurchaseOrders(
              this.poFilterParams
            );
          } else {
            return this.purchaseOrdersService.getPurchaseOrdersBySupplierNumber(
              this.poFilterParams
            );
          }
        })
      )
      .subscribe({
        next: (data: any) => {
          this.purchaseOrdersList = data.poList;
          this.purchaseOrderTotal = data.total;
          this.loadingTable = false;
        },
        error: () => {
          this.loadingTable = false;
          this.listenFilterStringControl();
        },
      });
  }

  initAlertBannner(status: boolean) {
    this.hasAlerts = status;
  }

  filterGroupBy(value: string) {
    this.poFilterParams.groupBy = value;
  }

  filterByDateRange() {
    this.getPurchaseOrders();
  }

  filterByPlant(event: any) {
    this.poFilterParams.plant = event;
    this.getPurchaseOrders();
  }

  filterByProductLine(value: string) {
    console.log(value);
    this.poFilterParams.productLine = value;
    this.getPurchaseOrders();
    // this.poFilterParams.groupBy = value;
  }

  filterByPurchaser(selection: INewUISelect) {
    this.poFilterParams.emailId = selection.value;
    localStorage.setItem(
      'custom-pages-purchaserSelected',
      JSON.stringify(selection)
    );
    this.getPurchaseOrders();
  }

  filterByStatus(selection: INewUISelect) {
    this.poFilterParams.status = selection.value;
    this.getPurchaseOrders();
  }
  filterByAlert(selection: any) {
    this.poFilterParams.alert = selection;
    this.getPurchaseOrders();
  }
  filterByDateRangeDropdown(dates: any) {
    this.setValuesForDateRageDropdown(dates);
  }

  clearFilters() {
    // Remove Params
    delete this.poFilterParams['search'];
    delete this.poFilterParams['status'];
    delete this.poFilterParams['sortBy'];
    delete this.poFilterParams['sortType'];
    delete this.poFilterParams['plant'];
    delete this.poFilterParams['productionMonth'];
    delete this.poFilterParams['originalShipDate'];
    delete this.poFilterParams['currentShipDate'];
    delete this.poFilterParams['emailId'];
    delete this.poFilterParams['alert'];

    // Clear Components
    this.statusSelect?.clear();
    this.purchaserSelect?.clear();
    this.dateRangeFilterDropdown?.clear();
    this.productLineFilter?.productLineCheckbox?.clear();

    localStorage.removeItem('custom-pages-purchaserSelected');

    this.preselectedPlant = [];
    this.poFilterParams.createDate = '';
    this.filterStringControl.setValue('');
    const pageData: PageChangedEvent = { itemsPerPage: 50, page: 1 };
    this.pageChanged(pageData);
    this.getPurchaseOrders();
  }

  setValuesForDateRageDropdown(dates: any) {
    if (dates.poCreateDate.from || dates.poCreateDate.to) {
      this.poFilterParams.createDate = this.poHelperService.stringForDateRange(
        dates.poCreateDate
      );
    }
    if (dates.productionMonth.from || dates.productionMonth.to) {
      this.poFilterParams.productionMonth =
        this.poHelperService.stringForDateRange(dates.productionMonth);
    }
    if (dates.originalShipDate.from || dates.originalShipDate.to) {
      this.poFilterParams.originalShipDate =
        this.poHelperService.stringForDateRange(dates.originalShipDate);
    }
    if (dates.currentShipDate.from || dates.currentShipDate.to) {
      this.poFilterParams.currentShipDate =
        this.poHelperService.stringForDateRange(dates.currentShipDate);
    }
    this.getPurchaseOrders();
  }

  getPurchaseOrders() {
    this.purchaseOrdersList = [];
    this.loadingTable = true;

    if (this.pageIsSummary) {
      this.purchaseOrdersSubscription &&
        this.purchaseOrdersSubscription.unsubscribe();
      this.purchaseOrdersSubscription = this.purchaseOrdersService
        .getSummaryPurchaseOrders(this.poFilterParams)
        .subscribe({
          next: (resp: IPurchaseOrdersListResponse) => {
            this.purchaseOrdersList = resp.poList;
            this.purchaseOrderTotal = resp.total;
            this.openQuantityTotal = resp.totalOpenQuantity;
          },
          complete: () => {
            this.loadingTable = false;
            this.purchaseOrdersSubscription.unsubscribe();
          },
          error: () => {
            this.loadingTable = false;
            this.purchaseOrderTotal = 0;
            this.purchaseOrdersSubscription.unsubscribe();
          },
        });
    }

    if (this.pageIsByModel || this.pageIsByPo) {
      this.purchaseOrdersSubscription &&
        this.purchaseOrdersSubscription.unsubscribe();
      this.purchaseOrdersSubscription = this.purchaseOrdersService
        .getPurchaseOrdersBySupplierNumber(this.poFilterParams)
        .subscribe({
          next: (resp: any) => {
            this.purchaseOrdersList = resp.poList;
            this.purchaseOrderTotal = resp.total;
          },
          complete: () => {
            this.loadingTable = false;
            this.purchaseOrdersSubscription.unsubscribe();
          },
          error: () => {
            this.loadingTable = false;
            this.purchaseOrderTotal = 0;
            this.purchaseOrdersSubscription.unsubscribe();
          },
        });
    }
  }

  onMessageHandlerErrorHandler() {
    this.subscription.add(
      this.websocketService
        .returnWebsocketMessageHandler()
        .subscribe((response: any) => {
          if (this.loadingPoPdf.loader) {
            this.loadingPoPdf.loader = false;
            this.loadingPoPdfIndex = undefined;
            clearTimeout(this.loadingPoPdf.timer);
            this.websocketService.setHandlerMessage('');
          }
          if (this.loadingReport.loader) {
            this.loadingReport.loader = false;
            clearTimeout(this.loadingReport.timer);
            this.websocketService.setHandlerMessage('');
          }
          if (response == 'No document found') {
            console.error(response);
            this.toastErrorText = response;
            this.errorToast?.show();
            this.websocketService.setHandlerMessage('');
          }
        })
    );
  }

  returnCurrencyFormat(currency: string, formatType: string) {
    switch (formatType) {
      case 'code':
        return this.purchaseOrdersService.getCurrencyCode(currency);
      case 'decimal':
        return this.purchaseOrdersService.getCurrencyDecimal(currency);
      default:
        return '';
    }
  }

  getPurchasers() {
    this.subscription.add(
      this.purchaseOrdersService.getAllPurchasers().subscribe((response) => {
        this.purchaserOptions = [
          ...this.poHelperService.setPurchaserOptions(response),
        ];
      })
    );
  }

  getValuesForPlant() {
    // Summary
    this.loadingPlants = true;
    if (this.pageIsSummary) {
      this.subscription.add(
        this.purchaseOrdersService
          .getAllPlants()
          .subscribe((response: number[]) => {
            this.plantNames = response;
            this.getPlantLabelsArray(this.plantNames);
            this.loadingPlants = false;
          })
      );
    }
    // By Supplier
    if (this.pageIsByModel || this.pageIsByPo) {
      this.subscription.add(
        this.purchaseOrdersService
          .getPlantsBySupplier(this.currentSupplierNumber)
          .subscribe((response: number[]) => {
            this.plantNames = response;
            this.getPlantLabelsArray(this.plantNames);
            this.loadingPlants = false;
          })
      );
    }
  }

  getPlantLabelsArray(plantNames: number[]) {
    plantNames.forEach((plantName: number) => {
      let plantLocation = plantLocations.find((plantObj) => {
        return plantName === plantObj.plant;
      })?.location;
      this.plantLabels.push(plantLocation || 'Other');
    });
  }

  private getPageFromRouteParameter() {
    this.poPageSetting = this.activatedRoute.snapshot.routeConfig?.path;
    switch (this.poPageSetting) {
      case 'by-po':
        this.pageIsByPo = true;
        break;
      case 'by-model':
        this.pageIsByModel = true;
        break;
      case 'summary':
        this.pageIsSummary = true;
        break;
    }
  }
}
