import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AllStatusObject,
  AllTableStatusObject,
  IAllStatusObject,
} from 'src/app/fixtures/purchase-orders/status-options';
import {
  IPoPurchaserResponse,
  PoStatusEndpointValue,
  PurchaseOrdersAlerts,
  PurchaseOrdersAlertsTitles,
} from 'src/app/models/purchase-orders';
import { INewUISelect } from 'src/app/models/ui-components';

@Injectable({
  providedIn: 'root',
})
export class PoHelperService {
  constructor() {}

  stringForDateRange(dateRange: any) {
    return `${
      dateRange.from
        ? dateRange.from.toISOString().split('-').join('').split('T')[0]
        : ''
    },${
      dateRange.to
        ? dateRange.to.toISOString().split('-').join('').split('T')[0]
        : ''
    }`;
  }

  setPurchaserOptions(
    purchaserObjArray: IPoPurchaserResponse[]
  ): INewUISelect[] {
    let purchaserOptions: INewUISelect[] = [];
    const savedPurchaser = this.getSavedPurchaser();
    purchaserObjArray.forEach((purchaserObj: IPoPurchaserResponse) => {
      purchaserOptions.push({
        text:
          purchaserObj.purchaserName ||
          purchaserObj.emailId?.toLocaleLowerCase(),
        value: purchaserObj.emailId,
        selected: savedPurchaser.value === purchaserObj.emailId,
      });
    });
    return purchaserOptions;
  }

  getSavedPurchaser() {
    const savedPurchaser = localStorage.getItem(
      'custom-pages-purchaserSelected'
    );
    return savedPurchaser !== null ? JSON.parse(savedPurchaser) : '';
  }

  setStatusOptions(): INewUISelect[] {
    let statusOptions: INewUISelect[] = [];
    AllStatusObject.forEach((status: IAllStatusObject) => {
      statusOptions.push({
        text: status.text,
        value: status.text,
        selected: false,
      });
    });
    return statusOptions;
  }

  setTableStatusOptions(): INewUISelect[] {
    let statusOptions: INewUISelect[] = [];
    AllStatusObject.forEach((status: IAllStatusObject) => {
      statusOptions.push({
        text: status.text,
        value: status.value,
        selected: false,
      });
    });
    return statusOptions;
  }

  findLastDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  compareDates(selectedDate: Date, lastDayOfMonth: Date): boolean {
    let dateToISO = selectedDate.toISOString().split('T')[0];
    let lastDayToISO = lastDayOfMonth.toISOString().split('T')[0];
    return dateToISO != lastDayToISO;
  }

  isDateRangeFilterFormEmpty(dateRangeFilterForm: UntypedFormGroup): boolean {
    return dateRangeFilterForm?.get('poCreateDate')?.get('from')?.value ||
      dateRangeFilterForm?.get('poCreateDate')?.get('to')?.value ||
      dateRangeFilterForm?.get('productionMonth')?.get('from')?.value ||
      dateRangeFilterForm?.get('productionMonth')?.get('to')?.value ||
      dateRangeFilterForm?.get('originalShipDate')?.get('from')?.value ||
      dateRangeFilterForm?.get('originalShipDate')?.get('to')?.value ||
      dateRangeFilterForm?.get('currentShipDate')?.get('from')?.value ||
      dateRangeFilterForm?.get('currentShipDate')?.get('to')?.value
      ? false
      : true;
  }

  returnAlertTitle(alertName: string): string {
    switch (alertName) {
      case PurchaseOrdersAlerts.SUPPLIER_RESPONSE:
        return PurchaseOrdersAlertsTitles.SUPPLIER_RESPONSE;
      case PurchaseOrdersAlerts.UPDATE_NEEDED:
        return PurchaseOrdersAlertsTitles.UPDATE_NEEDED;
      case PurchaseOrdersAlerts.UPCOMMING_SHIP_DATE:
        return PurchaseOrdersAlertsTitles.UPCOMMING_SHIP_DATE;
      default:
        return 'Other';
    }
  }

  selectableStatusOnPoTable(status: string): boolean {
    return status !== PoStatusEndpointValue.NEW;
  }
}
