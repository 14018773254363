import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ROLES } from 'src/app/models/role';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';
import { IdentityService } from 'src/app/services/common/identity.service';
import { SelectSupplierHelperService } from 'src/app/services/helper/select-supplier-helper.service';
import { ILookupMaterials, ILookupRecentSearches } from '../../models/lookup';
import { LookupService } from '../../services/lookup/lookup.service';
import { HelperService } from '../../services/helper/helper.service';

@Component({
  selector: 'app-lookup-search',
  templateUrl: './lookup-search.component.html',
})
export class LookupSearchComponent implements OnInit {
  @Input() dateFormat!: string;
  @ViewChild('search') searchElement!: ElementRef;

  selectedProduct?: ILookupMaterials;
  recentSearchesList: ILookupRecentSearches[] | undefined = undefined;

  // User
  mUser: any;
  supplierNumber: string = '';
  userDateFormat: string = '';

  // Alerts
  loadingSearches: boolean = true;
  noResultAlert: boolean = false;

  // Boolean
  serachesListValue: boolean = false;
  serachesListEmpty: boolean = false;
  showRecentSearches: boolean = false;

  // Form Control
  searchQuery = new UntypedFormControl('');

  // Subscription
  subscription: Subscription = new Subscription();

  constructor(
    private lookupService: LookupService,
    private selectSupplierHelperService: SelectSupplierHelperService,
    private route: Router,
    private identityService: IdentityService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private helperService: HelperService
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.clearAndFocusSearchInput();
    this.getRecentSearches();
    this.getUserInfo();
    this.lookupService.setSharedMaterial(undefined);
  }

  getUserInfo() {
    this.identityService.getCurrentMeUser().subscribe({
      next: (user) => {
        if (user) {
          this.mUser = user;
          this.setDateFormat();
          this.checkUserRole(this.mUser);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  setDateFormat() {
    this.userDateFormat = this.mUser.dateFormat
      ? this.mUser.dateFormat
      : 'dd-MM-yyyy';
  }

  checkUserRole(user: any) {
    let userRole = user.role;
    if (
      userRole === ROLES.FMIC_ADMIN ||
      userRole === ROLES.FMIC_CUSTOMER_SERVICE
    ) {
      this.supplierNumber = this.checkForSupplierNumber();
    } else if (
      userRole === ROLES.SUPPIER_ADMIN ||
      userRole === ROLES.SUPPIER_FACTORY ||
      userRole === ROLES.SUPPIER_FINANCE ||
      userRole === ROLES.SUPPIER_MANAGER
    ) {
      this.supplierNumber = user.supplierNumber;
    }
  }

  getRecentSearches() {
    this.subscription.add(
      this.lookupService.getRecentSearches().subscribe({
        next: (data) => {
          this.recentSearchesList = data;
          this.loadingSearches = false;
        },
        error: () => {
          this.loadingSearches = false;
        },
        complete: () => {},
      })
    );
  }

  checkForSupplierNumber() {
    let supplierNumber = '';
    this.selectSupplierHelperService.getValue().subscribe((value) => {
      if (value) {
        supplierNumber = value;
      }
    });
    return supplierNumber;
  }

  searchFromRecent(material: string) {
    this.googleAnalyticsService.trackEvent(
      'lookup_widget',
      'Search from Recent',
      material
    );
    this.onSearch(material, true);
  }

  onClear() {
    this.selectedProduct = undefined;
    this.getRecentSearches();
  }

  onSearch(material: string, fromRecent?: boolean) {
    this.noResultAlert = false;
    if (material.length == 0) {
      if (this.selectedProduct) {
        this.getRecentSearches();
      }
      this.selectedProduct = undefined;
    } else {
      const composedMaterial = this.helperService.addLeadingZeros(material, 10);
      this.checkUserRole(this.mUser);
      this.loadingSearches = true;
      this.loadingSearches = true;
      let supplierNumber = this.supplierNumber;
      this.subscription.add(
        this.lookupService
          .getMaterial(composedMaterial, supplierNumber)
          .subscribe({
            next: (data) => {
              if (data) {
                this.selectedProduct = data;
                this.loadingSearches = false;
                if (!fromRecent) {
                  this.googleAnalyticsService.trackEvent(
                    'lookup_widget',
                    'Search Successful',
                    material
                  );
                }
              }
            },
            error: (err) => {
              this.loadingSearches = false;
              this.noResultAlert = true;
              this.selectedProduct = undefined;
              this.googleAnalyticsService.trackEvent(
                'lookup_widget',
                'Search Unsuccessful',
                material
              );
              console.error(err);
            },
            complete: () => {},
          })
      );
      this.searchQuery.setValue('');
    }
  }

  viewInLookupPage(material: string) {
    this.googleAnalyticsService.trackEvent(
      'lookup_widget',
      'Click View',
      material
    );
    this.lookupService.setSharedMaterial(this.selectedProduct);
    this.route.navigate([`lookup/${material}`]);
  }

  clearAndFocusSearchInput() {
    this.searchQuery.setValue('');
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0);
  }
}
