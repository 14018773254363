import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styles: [],
})
export class NoteModalComponent implements OnInit {
  @Input() public poNumber?: string;

  @Output() onCloseNoteModal = new EventEmitter();
  @Output() onSaveNote = new EventEmitter<string>();

  noteControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(500),
  ]);

  constructor() {}

  ngOnInit(): void {}

  saveNote() {
    this.onSaveNote.emit(this.noteControl.value);
    this.closeNoteModal();
  }
  closeNoteModal() {
    this.onCloseNoteModal.emit();
  }
}
