import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup } from '@angular/forms';
import { HelperService } from 'src/app/services/helper/helper.service';
import { StorageManagerService } from 'src/app/services/managers/storage-manager.service';
@Component({
  selector: 'app-reactive-file-input',
  templateUrl: './reactive-file-input.component.html',
})
export class ReactiveFileInputComponent implements OnInit {
  @Input() formParent!: UntypedFormGroup;
  @Input() controlName: string = '';
  @Input() extensionsAllowed: string[] = [];
  @Input() formatsAllowed: string[] = [];
  @Input() maxMBs = 5;
  @Input() fieldName: string = '';
  @Input() selectedFileName: string = '';
  @Input() onEdit: boolean = false;
  @Input() canRemove = true;
  @Input() uuid = false;
  @Input() disabled = false;
  @Input() useFileUploadService = false;
  @Input() required = false;
  @Input() labelText = 'Choose File';
  @Input() buttonText = 'Choose File';
  @Output() uploadedFileName = new EventEmitter();
  public file: any;
  public maxSize!: number;

  // Errors
  formatError: boolean = false;
  maxSizeError: boolean = false;

  constructor(
    private helperService: HelperService,
    private storageManager: StorageManagerService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.onEdit) {
      this.formParent.controls[this.controlName].setValue(
        this.selectedFileName
      );
    }
  }

  ngOnInit(): void {
    if (this.uuid) {
      this.fieldName = this.helperService.guidGenerator();
    }
    if (this.disabled) {
      this.formParent.get(this.controlName)?.disable();
    }
    this.maxSize = this.maxMBs * 1024 * 1024;
  }

  async onFileSelect(event: any) {
    this.file = event.target.files[0];
    if (this.file) {
      let sizeValid = !this.haveSizeErrors(this.file.size);
      let formatValid = !this.haveFileFormartError(this.file.name);
      if (sizeValid && formatValid) {
        // ===> useFileUploadService <====
        let fileName = this.file.name as string;
        const type = this.file.type
          ? this.file.type.split(';')[0].split('/')[1]
          : fileName.split('.').pop();
        let cleanFilename = this.helperService.getCleanFileName(fileName, type);
        const uploaded = await this.storageManager.uploadFile(
          cleanFilename,
          this.file
        );
        this.uploadedFileName.emit(uploaded);
      }
    } else {
      this.formatError = false;
      this.maxSizeError = false;
    }
  }

  haveSizeErrors(size: number) {
    if (size <= this.maxSize) {
      return (this.maxSizeError = false);
    } else {
      this.formParent.controls[this.controlName].setErrors({ incorrect: true });
      return (this.maxSizeError = true);
    }
  }

  haveFileFormartError(fileName: string) {
    let fileExtension = fileName ? fileName.split('.').pop() : '';
    if (
      fileExtension &&
      this.extensionsAllowed &&
      this.extensionsAllowed.length &&
      !this.extensionsAllowed.includes(fileExtension)
    ) {
      this.formParent.controls[this.controlName].setErrors({ incorrect: true });
      return (this.formatError = true);
    } else {
      return (this.formatError = false);
    }
  }

  onRemove() {
    this.formParent.controls[this.controlName].reset();
    this.formatError = false;
    this.maxSizeError = false;
    this.onEdit = false;
  }
}
