import { Interface } from 'readline';

export interface IUser {
  enable?: boolean;
  name?: string;
  userId?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  email?: string;
  unitOfMeasure?: string;
  role: string;
  dateFormat?: string;
  timeFormat?: string;
  timezone?: string;
  updatedBy?: string;
  createdBy?: string;
  createdUpdatedBy?: string;
  passwordUpdatedAt?: string;
  lastLoginAt?: string;
  loginCount?: number;
  countryCode?: string;
  supplierNumber?: string;
  emailOptIn?: string[];
  emailOptInData?: EmailOptInData;
}

export interface EmailOptInData {
  onDocumentCreated: OnDocumentCreated;
  onPoCreated: OnPoCreated;
}

export interface OnDocumentCreated {}

export interface OnPoCreated {
  supplierNumbers: string[];
}

export interface IUserListParams {
  limit: number;
  offset: number;
  sortType: string;
  sortBy: string;
  search: string;
  supplierNumber?: string;
}

export interface IUserFormats {
  unitOfMeasure?: string;
  dateFormat?: string;
  timeFormat?: string;
  timezone?: string;
  emailOptIn?: string[];
  emailOptInData?: EmailOptInData;
}

export enum EmailOptIn {
  DOCUMENTS = 'Documents',
  PURCHASE_ORDERS = 'Purchase Orders',
}
