import { GoogleAnalyticsService } from './../../../services/common/google-analytics.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IDocument } from 'src/app/models/document';
import { IUserFormats } from 'src/app/models/user';
import { DocumentService } from 'src/app/services/documents/document.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { StorageManagerService } from 'src/app/services/managers/storage-manager.service';
import { SelectSupplierHelperService } from '../../../services/helper/select-supplier-helper.service';
import { ROLES } from 'src/app/models/role';
@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html',
})
export class MyDocumentsComponent implements OnInit, OnDestroy {
  @Input() isWidget: boolean = true;
  @Input() userRole: string = '';
  @Input() userFormats: IUserFormats = {
    timezone: '',
    unitOfMeasure: '',
    dateFormat: '',
    timeFormat: '',
  };

  supplierNumber: string | undefined;
  loading: boolean = false;
  showError: boolean = false;
  searchDocuments: UntypedFormGroup;
  searchSelected: boolean = false;
  isCollapse: boolean = false;
  showControls: boolean = true;
  showSeeMoreBtn: boolean = true;
  isFullscreen: boolean = false;
  isOnDashboard: boolean = true;
  limit: number | undefined = undefined;
  subscription: Subscription = new Subscription();
  documentsList: IDocument[] = [];
  documents: IDocument[] = [];
  filterString: string = '';
  sort!: { column: string; reverse: boolean };
  noDataListError: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private documentService: DocumentService,
    private helperService: HelperService,
    private storageManagerService: StorageManagerService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private selectSupplierHelperService: SelectSupplierHelperService,
  ) {
    this.searchDocuments = formBuilder.group({
      searchInput: new UntypedFormControl(''),
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userRole'].currentValue) {
      if (this.userRole) {
        this.getDocumentsOnRole(this.userRole);
      }
    }
  }

  ngOnInit(): void {
    if (this.isWidget) {
      this.limit = 5;
    }
    this.loading = true;
    this.showError = false;
    this.sort = { column: '', reverse: true };
  }

  getDocumentsOnRole(userRole: string) {
    if (
      userRole === ROLES.FMIC_ADMIN ||
      userRole === ROLES.FMIC_CUSTOMER_SERVICE
    ) {
      this.selectSupplierHelperService.getValue().subscribe((value) => {
        if (value) {
          this.getDocumentsBySupplier(value);
        } else {
          this.getDocuments();
        }
      });
    } else {
      this.getDocuments();
    }
  }

  toggleSearchInput() {
    this.searchSelected = !this.searchSelected;
  }

  changeSort(sort: any) {
    this.sort.reverse = this.sort.column === sort ? !this.sort.reverse : false;
    this.sort.column = sort;
    this.documentsList = this.filterSortDocumentsList(
      this.documents,
      this.filterString,
      this.sort
    );
  }

  filterDocuments() {
    this.documentsList = this.filterSortDocumentsList(
      this.documents,
      this.filterString,
      this.sort
    );
  }

  filterSortDocumentsList(
    documents: IDocument[],
    searchString: string,
    sort: { column: string; reverse: boolean }
  ) {
    let documentList: IDocument[] = [];
    if (searchString !== '' && searchString.length) {
      documentList = this.userTextSearch(documents, searchString);
    } else {
      documentList = this.documents;
    }
    documentList = this.helperService.nestedSort(
      documentList,
      sort.column,
      sort.reverse
    );
    documentList = this.helperService.emptyToEnd(documentList, sort.column);
    return documentList;
  }

  userTextSearch(documents: IDocument[], filterString: string) {
    return documents.filter((user: any) => {
      return (
        user.title.toLowerCase().indexOf(filterString.toLowerCase()) >= 0 ||
        user.description.toLowerCase().indexOf(filterString.toLowerCase()) >= 0
      );
    });
  }

  controlsCollapse() {
    this.isCollapse = !this.isCollapse;
  }

  onFullscreen() {
    this.isCollapse = false;
    this.showSeeMoreBtn = false;
    this.showControls = false;
    this.isFullscreen = true;
  }

  onExitFullScreen() {
    this.showSeeMoreBtn = true;
    this.showControls = true;
    this.isFullscreen = false;
  }

  onClose() {
    this.isOnDashboard = false;
  }
  download(filename: string | undefined, title: string) {
    if (!filename) {
      return;
    }
    this.googleAnalyticsService.trackEvent('documents', 'Download', title);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', filename);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  viewUrl(url: string | undefined, title: string) {
    if (!url) {
      return;
    }
    this.googleAnalyticsService.trackEvent('documents', 'View', title);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getDocuments() {
    this.loading = true;
    this.showError = false;
    this.subscription.add(
      this.documentService.getActiveDocuments().subscribe({
        next: (list: IDocument[]) => {
          if (list) {
            this.documents = list;
            this.documentsList = list;
          }
        },
        complete: () => {
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.showError = true;
        },
      })
    );
  }

  getDocumentsBySupplier(supplierNumber: string) {
    this.loading = true;
    this.showError = false;
    this.subscription.add(
      this.documentService
        .getActiveDocumentsBySupplier(supplierNumber)
        .subscribe({
          next: (list: IDocument[]) => {
            if (list) {
              this.documents = list;
              this.documentsList = list;
            }
          },
          complete: () => {
            this.loading = false;
          },
          error: () => {
            this.loading = false;
            this.showError = true;
          },
        })
    );
  }
}
