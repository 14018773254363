import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IdentityService } from '../../../services/common/identity.service';
import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
} from 'ngx-bootstrap/datepicker';
import { DateRangeFilterTitles } from 'src/app/fixtures/purchase-orders/date-range-filter';
import { debounceTime, first, skip, take } from 'rxjs';
import { PoHelperService } from '../../../services/purchase-orders/po-helper.service';

@Component({
  selector: 'app-po-date-range-section',
  templateUrl: './po-date-range-section.component.html',
  styles: [],
})
export class PoDateRangeSectionComponent implements OnInit {
  @Input() public title: string = '';
  @Input() public form!: UntypedFormGroup;

  minDate: Date | undefined;
  maxDate: Date | undefined;

  bsConfig?: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-red',
    showWeekNumbers: false,
  };

  constructor(
    private identityService: IdentityService,
    private poHelperService: PoHelperService
  ) {}

  ngOnInit(): void {
    this.identityService.getCurrentMeUser().subscribe((user) => {
      this.setUserFormat(user?.dateFormat?.toLocaleUpperCase());
    });
    this.onDateChanges();
    if (this.title === DateRangeFilterTitles.PRODUCTION_MONTH) {
      this.setMonthMode();
      this.onMonthModeChanges();
    }
  }

  setUserFormat(userFormat: string | undefined) {
    this.bsConfig = Object.assign(this.bsConfig as {}, {
      dateInputFormat: userFormat || 'MM-DD-YYYY',
    });
  }

  setMonthMode() {
    const minMode: BsDatepickerViewMode = 'month';
    this.bsConfig = Object.assign(this.bsConfig as {}, {
      minMode: minMode,
    });
  }

  onDateChanges() {
    this.form.get('from')?.valueChanges.subscribe((fromDate) => {
      this.minDate = fromDate;
    });
    this.form.get('to')?.valueChanges.subscribe((toDate) => {
      this.maxDate = toDate;
    });
  }

  onMonthModeChanges() {
    this.form.get('to')?.valueChanges.subscribe((toDate) => {
      const lastDayOfMonth = this.poHelperService.findLastDayOfMonth(toDate);
      const distinctDates = this.poHelperService.compareDates(
        toDate,
        lastDayOfMonth
      );
      if (distinctDates) {
        this.form.get('to')?.setValue(lastDayOfMonth);
      }
    });
  }
}
