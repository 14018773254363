import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { LoginComponent } from './authentication/login/login.component';
import { AuthenticationGuard } from './authentication/guards/authentication.guard';
import { CompletePasswordComponent } from './authentication/complete-password/complete-password.component';
import { DocumentsPageComponent } from './dashboard/documents/documents-page/documents-page.component';
import { BrowserSupportComponent } from './ui/browser-support/browser-support.component';
import { HelpPageComponent } from './dashboard/help/help-page/help-page.component';
import { EventsPageComponent } from './dashboard/events/events-page/events-page.component';
import { LookupPageComponent } from './dashboard/lookup/lookup-page/lookup-page.component';
import { EventType } from './models/events';
import { SupplierFactoryGuard } from './authentication/guards/supplier-factory.guard';
const routes: Routes = [
  //{ path: 'login', redirectTo: 'AppComponent', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'activate', component: CompletePasswordComponent },
  {
    path: 'dashboard',
    canActivate: [AuthenticationGuard, SupplierFactoryGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(
        (module) => module.DashboardModule
      ),
  },
  {
    path: 'account-maintenance',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./account-maintenance/account-maintenance.module').then(
        (module) => module.AccountMaintenanceModule
      ),
  },
  {
    path: 'documents',
    component: DocumentsPageComponent,
    canActivate: [AuthenticationGuard, SupplierFactoryGuard],
  },
  {
    path: 'help',
    canActivate: [AuthenticationGuard, SupplierFactoryGuard],
    loadChildren: () =>
      import('./dashboard/help/help.module').then(
        (module) => module.HelpModule
      ),
  },
  {
    path: 'purchase-orders',
    canActivate: [AuthenticationGuard, SupplierFactoryGuard],
    loadChildren: () =>
      import('./dashboard/purchase-orders/purchase-orders.module').then(
        (module) => module.PurchaseOrdersModule
      ),
  },
  {
    path: 'events',
    canActivate: [AuthenticationGuard, SupplierFactoryGuard],
    data: { eventType: EventType.OSE },
    loadChildren: () =>
      import('./dashboard/events/events.module').then(
        (module) => module.EventsModule
      ),
  },
  {
    path: 'custom-pages',
    canActivate: [AuthenticationGuard, SupplierFactoryGuard],
    data: { eventType: EventType.CUSTOM_PAGES },
    loadChildren: () =>
      import('./dashboard/events/events.module').then(
        (module) => module.EventsModule
      ),
  },
  {
    path: 'lookup',
    component: LookupPageComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'lookup/:materialId',
    component: LookupPageComponent,
    canActivate: [AuthenticationGuard],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
