<bitmovin-video-player
  [eventId]="eventId"
  [videoTitle]="videoTitle"
  [videoId]="videoId"
  [videoUrl]="videoUrl"
  [trackActivity]="trackActivity"
  [videoActivity]="videoActivity"
  [videoSubtitles]="videoSubtitles"
  [eventCategory]="eventCategory"
  [pageSelected]="pageSelected"
  [pageTitle]="pageTitle"
  [startTime]="startTime"
  (activityChange)="onActivityChange($event)"
  (onCloseVideo)="closeVideo()"
  (onSubtitleSelected)="onSubtitleSelected($event)"
  (statusChanged)="onStatusChanged($event)"
></bitmovin-video-player>
