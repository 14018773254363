<div class="video-thumbnail">
  <div
    id="video-thumbnail_container"
    class="video-thumbnail_container mb-3"
    [hidden]="!video?.url && loading"
  >
    <div
      class="video-thumbnail_image-container"
      [class.inline]="showInline"
      *ngIf="thumbnail"
      (click)="selectVideo()"
      [style.background-image]="'url(' + thumbnail + ')'"
    >
      <video
        [style.max-height]="maxHeight ? maxHeight : null"
        [style.max-width]="maxWidth ? maxWidth : null"
        [ngStyle]="{ width: width, height: height, visibility: 'hidden' }"
        *ngIf="showImage"
      >
        <source src="{{ video?.url }}" type="video/mp4" />
      </video>
    </div>
    <!-- no thumb -->
    <div
      class="video-thumbnail_video-container"
      [class.inline]="showInline"
      *ngIf="!thumbnail"
      (click)="selectVideo()"
    >
      <video
        [style.max-height]="maxHeight ? maxHeight : null"
        [style.max-width]="maxWidth ? maxWidth : null"
        [ngStyle]="{ width: width, height: height }"
        *ngIf="showImage"
      >
        <source src="{{ video?.url }}" type="video/mp4" />
      </video>
      <div
        class="video-overlay d-flex justify-content-center align-items-center"
      >
        <i class="fa-solid fa-play fa-lg text-white"></i>
      </div>
    </div>
  </div>
  <div class="row loading" [hidden]="!loading">
    <div class="col-xs-12">
      <app-fender-tone-loading-div [inline]="true">
      </app-fender-tone-loading-div>
    </div>
  </div>
</div>
