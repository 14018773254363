export interface IRole {
  id: number;
  role: string;
  code: string;
  description: string;
  category: string;
  sortingOrder?: number | string;
  checked?: boolean;
}

export enum ROLES {
  FMIC_ADMIN = 'FMIC-Admin',
  FMIC_CUSTOMER_SERVICE = 'FMIC-Customer-Service',
  FMIC_INTERNAL = 'FMIC-Fender-Internal',
  SUPPIER_ADMIN = 'Supplier-Admin',
  SUPPIER_MANAGER = 'Supplier-Manager',
  SUPPIER_FINANCE = 'Supplier-Finance',
  SUPPIER_FACTORY = 'Supplier-Factory',
  FMIC_MANAGER = 'FMIC-Fender-Manager',
}

export enum FenderRoles {
  FMIC_ADMIN = 'FMIC-Admin',
  FMIC_CUSTOMER_SERVICE = 'FMIC-Customer-Service',
  FMIC_INTERNAL = 'FMIC-Fender-Internal',
  FMIC_MANAGER = 'FMIC-Fender-Manager',
}

export enum SupplierRoles {
  SUPPIER_ADMIN = 'Supplier-Admin',
  SUPPIER_MANAGER = 'Supplier-Manager',
  SUPPIER_FINANCE = 'Supplier-Finance',
  SUPPIER_FACTORY = 'Supplier-Factory',
}

export enum RoleType {
  FMIC = 'FMIC-Role',
  SUPPLIER = 'Supplier-Role',
}
