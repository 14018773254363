import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { filter, Observable, Subscription } from 'rxjs';
import { ROLES } from 'src/app/models/role';
import { SelectSupplierHelperService } from 'src/app/services/helper/select-supplier-helper.service';
import { IUser } from '../../models/user';
import { IdentityService } from '../../services/common/identity.service';
import { PurchaseOrdersRoutes } from 'src/app/models/purchase-orders';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrdersAccess  {
  subscription: Subscription = new Subscription();
  mUser!: IUser;
  urlSuppleriNumber: string | null = null;

  constructor(
    private selectSupplierHelperService: SelectSupplierHelperService,
    private identityService: IdentityService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const poRoute = state.url.split('/').pop();
    let urlSupplierNumber = route.queryParams['supplierNumber'];
    return new Observable<boolean>((obs) => {
      this.identityService
        .getCurrentMeUser()
        .pipe(filter<any>(Boolean))
        .subscribe((value) => {
          if (value) {
            this.mUser = value;
            // FMIC Admin & Customer Service
            if (
              this.mUser.role === ROLES.FMIC_ADMIN ||
              this.mUser.role === ROLES.FMIC_CUSTOMER_SERVICE
            ) {
              obs.next(this.checkForFMIC(poRoute, urlSupplierNumber));
              return;
            }
            // FMIC Manager
            if (this.mUser.role === ROLES.FMIC_MANAGER) {
              obs.next(this.checkForFMICManager(poRoute));
              return;
            }
            // Supplier Users
            if (
              this.mUser.role === ROLES.SUPPIER_ADMIN ||
              this.mUser.role === ROLES.SUPPIER_MANAGER ||
              this.mUser.role === ROLES.SUPPIER_FINANCE
            ) {
              obs.next(this.checkForSuppliers(poRoute));
              return;
            }
            this.router.navigate(['/dashboard']);
            obs.next(false);
          }
        });
    });
  }

  // ============================== Private Functions ==============================

  // FMIC Admin & Customer Service
  private checkForFMIC(
    poRoute: string | undefined,
    urlSupplierNumber?: string
  ) {
    let selectedSupplier = this.getSelectedSupplier();
    if (selectedSupplier || urlSupplierNumber) {
      urlSupplierNumber = undefined;
      return true;
    } else if (
      poRoute !== PurchaseOrdersRoutes.BU_MODEL &&
      poRoute !== PurchaseOrdersRoutes.BY_PO
    ) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }

  // FMIC Manager
  private checkForFMICManager(poRoute: string | undefined) {
    if (
      poRoute !== PurchaseOrdersRoutes.BU_MODEL &&
      poRoute !== PurchaseOrdersRoutes.BY_PO
    ) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }

  // Supplier Users
  private checkForSuppliers(poRoute: string | undefined) {
    if (poRoute !== PurchaseOrdersRoutes.SUMMARY) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }

  private getSelectedSupplier(): string | undefined {
    return this.selectSupplierHelperService.selectedSupplier.value;
  }
}
